<!--舌面照-->
<template>
	<div class="consu-container">
		<v-consultation-header></v-consultation-header>
		<div class="wrap-content">
			<ul class="wrap">
				<li class="bordered">
					<h1>舌照</h1>
					<div class="pic-text">请上传舌苔和舌底的清晰照片</div>
					<van-uploader :before-read="beforeRead" class="pic-uploader" accept="image/*" @delete="deleteFile1" v-model="fileList1" :after-read="afterRead" :max-count="5">
						<div class="upload-pic-icon"></div>
					</van-uploader>
					<div class="pic-tips">*请在光线充足的室内，使用后置摄像头拍摄</div>
					<div class="pic-tips">*在距离面部约20cm处（一前臂距离）拍摄舌照</div>
					<div class="pic-tips">*拍摄时关闭美颜功能，注意对焦舌头</div>
					<div class="pic-tips">*拍舌苔时，舌头向下伸长；拍舌底时，舌头向上抬起</div>
					<div class="pic-example-list">
						<img src="../../assets/images/pic-example1.png" />
						<img src="../../assets/images/pic-example2.png" />
						<img src="../../assets/images/pic-example3.png" />
						<img src="../../assets/images/pic-example4.png" />
					</div>
				</li>
				<li class="bordered">
					<h1>面照</h1>
					<div class="pic-text">请上传正面素颜彩色照片，如需要也可拍摄其它患部照片</div>
					<van-uploader :before-read="beforeRead" class="pic-uploader" accept="image/*" v-model="fileList2" @delete="deleteFile2" :after-read="afterRead" :max-count="5">
						<div class="upload-pic-icon"></div>
					</van-uploader>
					<div class="pic-tips">*请在自然光线下拍摄面部正面素颜照</div>
				</li>
				<!-- <li>
					<h1>线下医院是否就诊过</h1>
					<van-radio-group v-model="formData.isToHospital" @change="validate">
						<van-grid :column-num="2" :border="false" :center="false">
							<van-grid-item>
								<van-radio :name="1" >就诊过</van-radio>
							</van-grid-item>
							<van-grid-item>
								<van-radio :name="2" >未就诊</van-radio>
							</van-grid-item>
						</van-grid>
					</van-radio-group>
				</li>
				<li v-if="formData.isToHospital === 1">
					<h1><span class="red-star">*</span>就诊医院</h1>
					<van-field v-model="formData.vHospital" @input="validate" placeholder="请输入就诊医院" />
				</li>
				<li v-if="formData.isToHospital === 1">
					<h1><span class="red-star">*</span>就诊结果</h1>
					<van-field v-model="formData.vResult" @input="validate" placeholder="请输入就诊结果" />
				</li>
				<li v-if="formData.isToHospital === 1">
					<h1><span class="red-star">*</span>如有相关的就诊病历、检查报告或化验单，请上传照片，已便医生更准确地为您诊疗（必填）</h1>
					<van-uploader :before-read="beforeRead" :max-size="5000 * 1024" class="pic-uploader" accept="image/*" v-model="fileList3" :after-read="afterRead" :max-count="5">
						<div class="upload-pic-icon"></div>
					</van-uploader>
				</li> -->
			</ul>
		</div>
		<div class="page-bottom">
			<div class="btn1" @click="gotoNext">
				<span>跳过该步骤</span>
			</div>
			<div class="btn2" :class="{'disabled': !canNext}" @click="gotoSubmit">
				<span>去提交</span>
			</div>
		</div>
	</div>
</template>
<script>
import vConsultationHeader from './consultation-header';
import { consultationAdd, getOssToken } from '@/request/api/user';
// uploadFile
import { mapGetters } from 'vuex';
import OSS from 'ali-oss';
import md5 from 'md5';

export default {
	data () {
		return {
			canNext: false,
			fileList1: [],
			fileList2: [],
			fileList3: [],
			uploadUrl: process.env.VUE_APP_BASE_URL + '/v1/users/upload-file',
			formData: {
				tongue1: '',
				tongue2: '',
				tongue3: '',
				tongue4: '',
				tongue5: '',
				face1: '',
				face2: '',
				face3: '',
				face4: '',
				face5: '',
				isToHospital: 1,
				vHospital: '',
				vResult: '',
				mr1: '',
				mr2: '',
				mr3: '',
				mr4: '',
				mr5: ''
			},
			userInfo: {},
			patientInfo: {}
		};
	},
	components: {
		vConsultationHeader
	},
	computed: {
		...mapGetters({
			patientList: 'getPatientList',
			stepThreeVal: 'getStepThree',
			stepOneVal: 'getStepOne',
			stepTwoVal: 'getStepTwo'
		})
	},
	mounted () {
		if (window.history && window.history.pushState) {
			history.pushState(null, null, document.URL);
			window.addEventListener('popstate', this.onBack, false);
		}
	},
	destroyed () {
		window.removeEventListener('popstate', this.onBack, false);
	},
	created () {
		if (this.patientList && this.patientList.length) {
			this.patientInfo = this.patientList.find(p => p.id === Number(this.$route.query.patientId));
		} else {
			this.$root.replace('/')
		}
		let userInfo = window.sessionStorage.getItem('userInfo');
		userInfo = (userInfo === 'undefined' || userInfo === 'null') ? '{}' : userInfo;
		this.userInfo = JSON.parse(userInfo);
		// console.log('this.stepTwoVal.qa====', this.stepTwoVal.qa);
		const fils1 = [];
		const fils2 = [];
		const fils3 = [];
		if (this.stepThreeVal && this.stepThreeVal.tongueFaceData) {
			this.formData = this.stepThreeVal.tongueFaceData;
			if (this.formData.tongue1) fils1.push({ url: this.formData.tongue1 });
			if (this.formData.tongue2) fils1.push({ url: this.formData.tongue2 });
			if (this.formData.tongue3) fils1.push({ url: this.formData.tongue3 });
			if (this.formData.tongue4) fils1.push({ url: this.formData.tongue4 });
			if (this.formData.tongue5) fils1.push({ url: this.formData.tongue5 });

			if (this.formData.face1) fils2.push({ url: this.formData.face1 });
			if (this.formData.face2) fils2.push({ url: this.formData.face2 });
			if (this.formData.face3) fils2.push({ url: this.formData.face3 });
			if (this.formData.face4) fils2.push({ url: this.formData.face4 });
			if (this.formData.face5) fils2.push({ url: this.formData.face5 });

			if (this.formData.mr1) fils3.push({ url: this.formData.mr1 });
			if (this.formData.mr2) fils3.push({ url: this.formData.mr2 });
			if (this.formData.mr3) fils3.push({ url: this.formData.mr3 });
			if (this.formData.mr4) fils3.push({ url: this.formData.mr4 });
			if (this.formData.mr5) fils3.push({ url: this.formData.mr5 });
			this.fileList1 = [...fils1];
			this.fileList2 = [...fils2];
			this.fileList3 = [...fils3];
			// this.fileList3 = [...fils3];
			this.validate();
		}
	},
	methods: {
		onBack () {
			this.$root.replace({
				path: '/consultation-two',
				query: { ...this.$route.query }
			});
		},
		gotoNext () {
			// console.log('this.formData=next===', this.formData);
			this.formData.isToHospital = 2;
			this.createConsultation((success, id) => {
				if (success) {
					this.$root.replace({
						path: '/consultation-pay',
						query: { ...this.$route.query, id }
					});
				}
			});
		},
		gotoSubmit () {
			if (this.canNext) {
				this.createConsultation((success, id) => {
					if (success) {
						this.$root.replace({
							path: '/consultation-pay',
							query: { ...this.$route.query, id }
						});
					}
				});
			}
		},
		createConsultation (cb) {
			const patientInfo = this.stepOneVal.patientInfo;
			const query = this.$route.query;
			// console.log('this.stepTwoVal.qa====', this.stepTwoVal.qa);
			this.formData.tongue1 = '';
			this.formData.tongue2 = '';
			this.formData.tongue3 = '';
			this.formData.tongue4 = '';
			this.formData.tongue5 = '';
			this.formData.face1 = '';
			this.formData.face2 = '';
			this.formData.face3 = '';
			this.formData.face4 = '';
			this.formData.face5 = '';
			this.formData.mr1 = '';
			this.formData.mr2 = '';
			this.formData.mr3 = '';
			this.formData.mr4 = '';
			this.formData.mr5 = '';
			for (let i = 0; i < this.fileList1.length; i++) {
				this.formData['tongue' + (i + 1)] = this.fileList1[i].url;
			}
			for (let i = 0; i < this.fileList2.length; i++) {
				this.formData['face' + (i + 1)] = this.fileList2[i].url;
			}
			for (let i = 0; i < this.fileList3.length; i++) {
				this.formData['mr' + (i + 1)] = this.fileList3[i].url;
			}
			const reqData = {
				...this.formData,
				...this.stepOneVal.form1,
				type: query.subType,
				departmentId: +query.departmentId,
				doctorId: +query.doctorId,
				patientId: +query.patientId,
				height: patientInfo.height,
				weight: patientInfo.weight,
				consultationType: this.stepTwoVal.consultationType,
				consultationAnswer: JSON.stringify(this.stepTwoVal.qa)
			};
			// console.log('reqData====', reqData);
			consultationAdd(reqData).then(data => {
				if (data && data === 'retry') {
					this.createConsultation(cb);
				} else if (data && data.id) {
					let qa = {};
					if (data.consultationAnswer) {
						qa = JSON.parse(data.consultationAnswer);
					}
					this.$store.commit('setStep', {
						step: 'stepTwo',
						obj: { qa: qa, consultationType: data.consultationType }
					});
					this.$store.commit('setStep', {
						step: 'stepConsultation',
						obj: { consultation: data }
					});
					cb(true, data.id);
				} else {
					cb(false);
				}
			});
		},
		beforeRead (file) {
			if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
				return true;
			} else {
				this.$toast('请上传jpg或png格式的图片');
				return false;
			}
		},
		afterRead (file, name) {
			file.status = 'uploading';
			file.message = '上传中';
			getOssToken().then(data => {
				const imgType = file.file.type.split('/')[1];
				const filename = file.file.name + file.file.size; // md5对图片名称进行加密
				const keyValue = '' + md5(new Date() + filename) + '.' + imgType;
				const client = new OSS({
					region: data.region,
					// secure: true,
					stsToken: data.SecurityToken,
					accessKeyId: data.AccessKeyId,
					accessKeySecret: data.AccessKeySecret,
					bucket: data.bucket
				});
				client
					.put('odat/im/' + this.userInfo.id + '/' + keyValue, file.file)
					// .multipartUpload(keyValue, file.file)
					.then((res) => {
						if (res.url) {
							file.url = res.url;
							file.status = 'uploaded';
							file.message = '';
							this.validate();
						} else {
							file.status = 'failed';
							file.message = '上传失败';
							this.$toast('图片上传失败');
							this.fileList1.splice(name.index, 1);
						}
					})
					.catch((err) => {
						console.log('message', err.message);
						file.status = 'failed';
						file.message = '上传失败';
						this.$toast('图片上传失败');
						this.fileList1.splice(name.index, 1);
					});
				// console.log('asssssss', this.fileList1);
			});
		},
		validate () {
			this.canNext = true;
			if (!this.fileList1.length || !this.fileList2.length || (this.formData.isToHospital === 1 && (!this.fileList3.length || !this.formData.vHospital || !this.formData.vResult))) {
				this.canNext = false;
			}
		},
		deleteFile1 (file, name) {
			// console.log('this.fileList1=====', this.fileList1, file, name);
			console.log('this.fileList1====', this.fileList1);
			this.validate();
		},
		deleteFile2 (file, name) {
			console.log('this.fileList1=====', this.fileList2, file, name);
			// this.fileList2.splice(name.index, 1);
			this.validate();
		},
		deleteFile3 (file, name) {
			this.fileList3.splice(name.index, 1);
			this.validate();
		}
	}
};
</script>
<style lang="less" scoped>
	.consu-container {
		// margin-bottom: 74px;
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
	}
	.wrap-content {
		padding: 10px 12px;
		flex: 1;
		overflow: auto;
	}
	/deep/.upload-pic-icon {
		background-image: url('../../assets/images/upload-pic-icon.png');
		width: 60px;
		height: 60px;
		background-size: 100%;
	}
	.wrap {
		background-color: #ffffff;
		padding: 0 15px;
		margin: 0;
		display: flex;
    flex-direction: column;
		li.bordered {
			border-bottom: 1px solid #F2F2F2;
			padding-bottom: 24px;
			margin-top: 19px;
		}
		li {
			font-size: 15px;
			h1 {
				font-size: 15px;
				font-weight: 500;
				color: #232830;
				line-height: 15px;
			}
			.pic-text {
				font-size: 13px;
				font-weight: 400;
				color: #8B8B8B;
				line-height: 14px;
				margin-top: 14px;
			}
			.pic-uploader {
				margin-top: 14px;
			}
			.pic-tips {
				font-size: 12px;
				font-weight: 400;
				color: #BEBEBE;
				line-height: 12px;
				margin-top: 7px;
			}
			.pic-example-list {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				margin-top: 10px;
				img {
					width: 60px;
					height: 60px;
					margin-left: 12px;
				}
				img:first-child {
					margin-left: 0;
				}
			}
		}
	}
	.red-star {
		color: #FF8D16;
	}
	.van-field {
		border: 1px solid #ACD1FF;
    border-radius: 3px;
		padding: 10px 12px;
		margin-top: 14px;
		font-size: 13px;
	}
	.page-bottom {
		background-color: #ffffff;
		padding: 6px 0;
		// position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		align-items: center;
		div.btn1 {
			margin-left: 12px;
			background: #FFFFFF;
			border-radius: 20px;
			border: 1px solid #FF6450;
			padding: 12px 16px;
			font-size: 14px;
			font-weight: 400;
			color: #FF6450;
			line-height: 14px;
			cursor: pointer;
			flex: 1;
			white-space: nowrap;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		div.btn2 {
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
			border-radius: 20px;
			font-size: 14px;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 14px;
			padding: 12px 91px;
			margin-left: 8px;
			cursor: pointer;
			flex: 2;
			margin-right: 12px;
			white-space: nowrap;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.btn2.disabled {
			opacity: 0.5;
		}
	}
	/deep/.van-uploader__preview .van-uploader__preview-image {
		width: 60px;
		height: 60px;
	}
</style>